.order-list {
  &__item {
    cursor: pointer;

    &:hover {
      background-color: #a8a7a7;
    }

    &--cancelled {
      background-color: #f76860;
    }

    &--taken {
      background-color: lightgrey;
    }

    &--pending {
      background-color: #f8fc7e;
    }

    &--available {
      background-color: lightgreen;
    }
  }
}

table {
  // overflow-x: auto;
  border-collapse: collapse;
  width: 100%;

  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
  }

  th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #5c5d5e;
    color: white;
  }

  @media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px) {
    // display block --> no longer takes the table format
    table,
    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
    }

    // this hides the original table headers
    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    tr {
      margin: 0 0 1rem 0;
    }

    td {
      border: none;
      border-bottom: 1px solid #eee;
      position: relative;
      padding-left: 50%;
    }

    // use before to place label before the data (acting like a header)
    td::before {
      position: absolute;
      top: 6px;
      left: 6px;
      width: 45%;
      padding-right: 10px;
      white-space: nowrap;
    }

    td:nth-of-type(1)::before {
      content: "Order ID";
    }
    td:nth-of-type(2)::before {
      content: "Submission Time";
    }
    td:nth-of-type(3)::before {
      content: "Service Time";
    }
    td:nth-of-type(4)::before {
      content: "Location";
    }
    td:nth-of-type(5)::before {
      content: "Service Type";
    }
    td:nth-of-type(6)::before {
      content: "Status";
    }
    td:nth-of-type(7)::before {
      content: "Assignee";
    }
  }
}
